import React from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Typography,
  Box,
  Avatar,
  useTheme,
  useMediaQuery,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  CardContent,
  Link,
  Chip,
} from '@mui/material';
import { useNavigate } from 'react-router';

import ceramicsImg from '@/assets/toolIcons/ceramics.png';
import electronicsImg from '@/assets/toolIcons/electronics.png';
import printersImg from '@/assets/toolIcons/printer.png';
import textileImg from '@/assets/toolIcons/textiles.png';
import woodworkingImg from '@/assets/toolIcons/woodworking.png';
import ROUTES from '@/constants/routes';
import { STRINGS } from '@/constants/strings';

interface AboutUsContentBlockProps {
  children: React.ReactNode;
  isMobile: boolean;
  title: string;
}

const AboutUsContentBlock = ({
  title,
  children,
  isMobile,
}: AboutUsContentBlockProps) => {
  if (isMobile) {
    return (
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    );
  }

  return (
    <Card sx={{ flex: 1 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
        {children}
      </CardContent>
    </Card>
  );
};

interface AboutUsProps {
  handleEmailClick: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const AboutUs = ({ handleEmailClick }: AboutUsProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const navigate = useNavigate();
  const joinWaitlistClick = () => {
    navigate(ROUTES.WAITLIST);
  };
  const donateClick = () => {
    window.open(STRINGS.goFundMeLink, '_blank');
  };
  const getInvolvedClick = () => {
    navigate(ROUTES.GETINVOLVED);
  };
  const classSignupClick = () => {
    window.open(STRINGS.classSignupLink, '_blank');
  };

  return (
    <Box sx={{ mb: 7 }}>
      <AboutUsTexts
        joinWaitlistClick={joinWaitlistClick}
        donateClick={donateClick}
        getInvolvedClick={getInvolvedClick}
        classSignupClick={classSignupClick}
      />

      <Box
        sx={{
          display: isMobile ? 'block' : 'flex',
          gap: 2,
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <AboutUsContentBlock
          title={STRINGS.toolsSectionTitle}
          isMobile={isMobile}
        >
          <ActivityList />
        </AboutUsContentBlock>

        <AboutUsContentBlock title="Where are you located?" isMobile={isMobile}>
          <Location joinWaitlistClick={joinWaitlistClick} />
        </AboutUsContentBlock>
      </Box>
    </Box>
  );
};

interface LocationProps {
  joinWaitlistClick: () => void;
}

const Location = ({ joinWaitlistClick }: LocationProps) => {
  const clickGoogleMaps = () => {
    window.open('https://maps.app.goo.gl/vS2Liu8JKSCidG866', '_blank');
  };

  return (
    <>
      <Typography paragraph>
        We’re excited to announce that Brooklyn Spark has found a home! Our new
        space is located at{' '}
        <strong>
          <Link
            component="button"
            onClick={clickGoogleMaps}
            color="secondary.main"
          >
            75 Stewart Ave, Brooklyn, NY 11237, Unit 110
          </Link>
        </strong>{' '}
        (a short walk from the Jefferson St station on the L). With{' '}
        <strong>2,100 sq. ft.</strong> of space, we’re creating a vibrant hub
        for creativity and community, surrounded by other amazing creative
        tenants.
      </Typography>

      <Typography paragraph>
        Moving in will take place this <strong>January</strong>, but you don’t
        have to wait to get started.{' '}
        <Link
          component="button"
          onClick={joinWaitlistClick}
          color="secondary.main"
        >
          Joining the waitlist
        </Link>{' '}
        ensures you’ll be among the first to experience the new space.
      </Typography>

      <Typography paragraph>
        Don’t miss your chance to be part of something special. Join us in
        building Brooklyn Spark from the ground up!
      </Typography>
    </>
  );
};

const activities = [
  {
    icon: (
      <img
        src={woodworkingImg}
        alt="Woodworking"
        style={{ height: 'auto', width: '65%' }}
      />
    ),
    name: 'Woodworking',
  },
  {
    icon: (
      <img
        src={printersImg}
        alt="3D Printers & Laser Cutters"
        style={{ height: 'auto', width: '65%' }}
      />
    ),
    name: '3D Printers & Laser Cutters',
  },
  {
    icon: (
      <img
        src={textileImg}
        alt="Textile Arts"
        style={{ height: 'auto', width: '65%' }}
      />
    ),
    name: 'Textile Arts',
  },
  {
    icon: (
      <img
        src={ceramicsImg}
        alt="Ceramics"
        style={{ height: 'auto', width: '65%' }}
      />
    ),
    name: 'Ceramics',
  },
  {
    icon: (
      <img
        src={electronicsImg}
        alt="Electronics"
        style={{ height: 'auto', width: '65%' }}
      />
    ),
    name: 'Electronics',
  },
];

const ActivityList = () => {
  const theme = useTheme();

  const itemSx = {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    margin: theme.spacing(1),
    width: 100,
  };

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <Typography variant="body1" sx={{ mb: 1 }}>
        Our space will open with a wide range of tools. Highlights include a{' '}
        <strong>4x4 CNC machine</strong>, a <strong>SawStop table saw</strong>,
        and a variety of power tools like a <strong>planer</strong>,{' '}
        <strong>jointer</strong>, and <strong>shaper</strong>, as well as power{' '}
        <strong>sanders</strong>.
      </Typography>
      <Typography variant="body1" sx={{ mb: 1 }}>
        Ceramics is coming soon! We’ll have a <strong>kiln</strong> and{' '}
        <strong>slab roller</strong>. Textile enthusiasts can look forward to a{' '}
        <strong>sewing machine</strong>, <strong>serger</strong>,{' '}
        <strong>floor loom</strong>, and <strong>table loom</strong>.
      </Typography>
      <Typography variant="body1" sx={{ mb: 1 }}>
        Additionally, we’ll offer advanced fabrication tools such as a{' '}
        <strong>3D printer</strong> and a <strong>laser cutter</strong>.
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'center',
        }}
      >
        {activities.map((activity, index) => (
          <Box key={index} sx={itemSx}>
            <Avatar
              sx={{
                bgcolor: 'background.default',
                height: 65,
                width: 65,
              }}
            >
              {activity.icon}
            </Avatar>
            <Typography
              variant="subtitle1"
              sx={{
                lineHeight: 1.3,
                mt: 0.8,
                textAlign: 'center',
              }}
            >
              {activity.name}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

type AboutUsTextsProps = {
  classSignupClick: () => void;
  donateClick: () => void;
  getInvolvedClick: () => void;
  joinWaitlistClick: () => void;
};

const AboutUsTexts = ({
  joinWaitlistClick,
  donateClick,
  getInvolvedClick,
  classSignupClick,
}: AboutUsTextsProps) => {
  return (
    <Box sx={{ mb: 4 }}>
      <Typography variant="h4" gutterBottom>
        About Us
      </Typography>

      <Typography paragraph>
        We are a{' '}
        <strong>
          volunteer-run organization of artists and makers driven by curiosity,
          invention, and skill sharing
        </strong>
        . Whether you’re a seasoned builder, inventor, crafty one, or a total
        newbie ready to get your hands dirty, our community is committed to
        fostering an encouraging environment in which you feel welcome.
      </Typography>

      <Typography variant="h4" gutterBottom>
        We Need Your Help!
      </Typography>

      <Chip
        label="Donate"
        onClick={donateClick}
        sx={{ mb: 1, mr: 1 }}
        variant="outlined"
      />
      <Chip
        label="Get Involved"
        onClick={getInvolvedClick}
        sx={{ mb: 1, mr: 1 }}
        variant="outlined"
      />
      <Chip
        label="Teach a Class"
        onClick={classSignupClick}
        sx={{ mb: 1, mr: 1 }}
        variant="outlined"
      />

      <Typography paragraph>
        Starting a makerspace like this is no small feat - we’re a{' '}
        <strong>volunteer-run, not-for-profit organization</strong>, and we need
        all the help we can get. From tools to rent, there are plenty of costs
        to cover, and your support makes all the difference.{' '}
        <strong>
          Every donation helps us create a space where creativity thrives and
          ensures we can keep the doors open for our amazing community of
          makers.
        </strong>
      </Typography>
      <Typography paragraph>
        If you’d like to help, consider donating through our{' '}
        <strong>
          <Link component="button" onClick={donateClick} color="secondary.main">
            GoFundMe
          </Link>
        </strong>
        . Alternatively, you can let us know how you’d like to support by
        filling out our{' '}
        <strong>
          <Link
            component="button"
            onClick={getInvolvedClick}
            color="secondary.main"
          >
            interest form
          </Link>
        </strong>
        . Every little bit helps, and we’re so grateful for your support!
      </Typography>
      <Typography paragraph>
        Are you an <strong>instructor and have a class idea</strong>? We’d love
        to hear from you!{' '}
        <strong>
          <Link
            component="button"
            onClick={classSignupClick}
            color="secondary.main"
          >
            Click here to submit your class proposal
          </Link>
        </strong>{' '}
        and help us expand our offerings for the Brooklyn Spark community.
      </Typography>

      <Typography variant="h4" gutterBottom>
        {'Who is this for?'}
      </Typography>
      <Typography paragraph>
        Brooklyn Spark invites{' '}
        <strong>people from all backgrounds and skill levels</strong> to come
        together to learn, create, and explore. We believe in the power of
        interaction, the value of lifelong learning, and the importance of
        providing opportunities to grow and share skills in a supportive space.
      </Typography>

      <Typography variant="h4" gutterBottom>
        How much will membership cost?
      </Typography>

      <Typography paragraph>
        Membership will be <strong>$165/month</strong>, but early members will
        enjoy a{' '}
        <strong>special discounted rate for the first three months</strong> as a
        thank-you for helping us launch.{' '}
        <strong>
          <Link
            component="button"
            onClick={joinWaitlistClick}
            color="secondary.main"
          >
            Join the waitlist
          </Link>
        </strong>{' '}
        to secure your spot!
      </Typography>
    </Box>
  );
};

export default AboutUs;
